.react-datepicker__day--outside-month {
  opacity: 0 !important;
  cursor: auto !important;
}

div.react-datepicker__day--disabled {
  color: #AEACAC !important;
}

.react-datepicker__day--disabled:hover {
  background-color: transparent !important;
}