.react-datepicker__header {
    background-color: #3C3C3E !important;
    border-bottom: none !important;
}

.react-datepicker__day--selected {
    background-color: #42CBE5 !important;
}

.react-datepicker__day:hover {
    border-radius: 0.3rem !important;
    background-color: #AEACAC !important;
}