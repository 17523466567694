.rbc-day-slot .rbc-time-slot {
  border-top: none;
}

.rbc-time-slot {
  display: block;
}


.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: transparent !important;
}

.rbc-event .rbc-selected {
  background-color: transparent !important;
}

.rbc-time-gutter div {
  background-color: #222223;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: none;
  box-shadow: none;
}

.rbc-current-time-indicator {
  display: none;
}

.rbc-events-container {
    display: flex;
    flex-direction: column;
    border-left: #222223 !important;
    border-color: #222223;
    border-width: 2px;
    border-top: none !important;
    justify-content: center;
    align-items: center;
  }

.rbc-allday-cell {
  display: none;
}

.rbc-button-link {
  background-color: #222223;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 0.25rem;
  width: 100%;
  height: 100%;
}

.rbc-time-header,
.rbc-time-content,
.rbc-time-header-cell,
.rbc-day-bg,
.rbc-today,
.rbc-now,
.rbc-header,
.rbc-event-content {
  border: none !important;
  border-bottom: none !important;
  border-top: none !important;
  box-shadow: none !important;
  cursor: default;
}

.rbc-time-header-content {
  cursor: default;
}

.rbc-time-view {
  height: 100% !important;
  overflow: hidden !important;
  border: none;
}

.rbc-time-header-content {
  border: none;
}

.rbc-time-content > * + * {
  margin-top: 0 !important;
}

.rbc-header {
  border-bottom: none !important;
}

.rbc-today,
.rbc-now {
  background-color: inherit !important;
}

.rbc-row,
.rbc-time-gutter,
.rbc-allday-cell {
  border: none !important;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected .rbc-background-event {
  background-color: #222223;
}

.rbc-event{
  height: 20rem;
}
.rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  color: #fff;
  width: 100%;
  text-align: left;
  outline: none;
}

.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 0;
  top: 0;
}


.rbc-event-label {
  display: none;
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
  border: none;
}

.rbc-timeslot-group {
  border-bottom: #222223 1px solid;
  border-left: none;
  border-right: none;
}

.rbc-today > .rbc-button-link {
  background-color: #3C3C3E;
}

.rbc-button-link > span {
  white-space: break-spaces;
}
