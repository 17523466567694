@tailwind base;
@tailwind components;
@tailwind utilities;

html *
{
   font-family: Inter;
}

input::placeholder {
   font-style: italic;
}